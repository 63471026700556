.f20{
    font-size: 20px
}
.f18{
    font-size: 18px
}
.f16{
    font-size: 16px
}
.b{
    font-weight: bold;
}
.brandColor{
    color:#bf2100
}
.image-container{
	height: 50vh;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.image-view-modal{
	backdrop-filter: blur(1.25rem);
	.modal-content{
		background: none;
		border: none;
	}
	.image-container{
		height: 80vh;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.image-view-slider-container{
		.slick-dots {
		margin-left: 0
		}
		
		.slick-dots li button:before,.slick-dots li.slick-active button:before {
			color: var(--ifm-color-primary)!important
		}
		
		.slick-thumb {
			bottom: -50px;
		}
		
		.slick-thumb li {
			height: 50px;
			width: 60px
		}
		
		.slick-thumb li img {
			filter: grayscale(100%);
		}
		
		.slick-thumb li.slick-active img {
			filter: grayscale(0)
		}
		
		.slides .slick-next,.slides .slick-prev {
			position: absolute;
			top: 50%;
			z-index: 1
		}
	}
		
}
