.main-label{
    // font-size: 1.8em;
    // margin-bottom: 1.7em;
    // font-weight: 700;
    font-size: 1.5rem;
    font-weight: 600;
}

.register-label{
    font-weight: 700;
}
.otp-field{
    display: flex;
}

.otp-field input {
    width: 3rem;
    // font-size: 32px;
    padding: 10px;
    text-align: center;
    border-radius: 0.5em;
    margin:2px;
    border: 2px solid #8e8c9085;
    font-weight: bold;
    outline: none;
    transition: all 0.1s;
}

.otp-verification{
    font-size: 1.2em;
    margin-right: 1em;
    margin-bottom: 1em;
}

.otp-field input:focus {
    // border: 2px solid #bf2100;
    // box-shadow: 0 0 2px 2px #d85238;
    border-color: #000;
    box-shadow: none;
}
.or{
    color: #b8b8b8;
}

.gmail-login{
    border: 1.5px solid #000 !important;
    border-radius: 0.5em !important;
    box-shadow: none !important;
    color: #000 !important;
    &:focus{
        outline: none !important;
    }
    &:hover{
        div{
            background-color: #f1f1f1 !important;
        }
        background-color: #f1f1f1 !important;
    }
    span{
        font-size: 1em !important;
        font-weight: 600 !important;
        font-family: Verdana;
    }
}