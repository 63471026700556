
.title {
    font-size: 5em;
    font-weight: 700;
    font-family: Helvetica, Arial;
}
.subtitle{
    text-align: center;
    font-size: 1.5em;
    font-weight: 300;
    font-family: Helvetica, Arial;
}

.emptyBtn{
    font-size: 1.2em !important;
    font-weight: 500;
    font-family: Helvetica, Arial;
    border-radius: 12px;
}

// .notFoundPage{
//     background-color: #F2F2F2;
// }
