.loginSection{

    .log-in{
        font-size: 1.5rem;
        font-weight: 600;
        // color: #bf2100;
        // border-bottom: 3px solid #bf2100;
    }

    .or{
        color: #b8b8b8;
    }

    .form-control{
        border-radius: 0.5em;
        &:focus{
            border-color: #000;
            box-shadow: none;
        }
    }
    .login-label{
        font-weight: 700;
    }

    .gmail-login{
        border: 1.5px solid #000 !important;
        border-radius: 0.5em !important;
        box-shadow: none !important;
        color: #000 !important;
        &:focus{
            outline: none !important;
        }
        &:hover{
            div{
                background-color: #f1f1f1 !important;
            }
            background-color: #f1f1f1 !important;
        }
        span{
            font-size: 1em !important;
            font-weight: 600 !important;
            font-family: Verdana;
        }
    }
}