.clickable{
    cursor: pointer;
}
.mainWrapper{
    background: #e9e9e95c;
}
.form-control{
    border-radius: 0.5em;
    &:focus{
        border-color: #000;
        box-shadow: none;
    }
}
.text-danger{
    font-size: 80%;
    color: #dc3545;
}
.submit-error{
    font-size: 95%;
    color: #dc3545;
}
.slider-arrow:active {
	background: #dedede;
	border: none;
}