// .loader {
//     border: 16px solid #f3f3f3;
//     border-radius: 50%;
//     border-top: 16px solid #bf2100;
//     width: 120px;
//     height: 120px;
//     -webkit-animation: spin 2s linear infinite; /* Safari */
//     animation: spin 2s linear infinite;
//   }
  
//   /* Safari */
//   @-webkit-keyframes spin {
//     0% { -webkit-transform: rotate(0deg); }
//     100% { -webkit-transform: rotate(360deg); }
//   }
  
//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }



  .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    // background: #222;
    backdrop-filter: blur(0.5px);
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999999999;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 5px;
    border-color: #fff;
    border-top-color: #bf2100;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}