.filter-by {
    background-color: #fff;
    font-size: large;
    font-weight: bold;
    border-radius: 10px;

    .filterSection {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }

    .filter-options {
        font-weight: 500; 
    }

    .bottom-border::before {
        content: "";
        position: absolute;
        left: 2.5em;
        right: 2.5em;
        bottom: 0;
        border-bottom: 3px solid #bf2100;
    }

    .bottom-border:hover::before {
        transform: scaleX(1.3) scaleY(1);
    }


    .isActive {
        transform: rotate(180deg);
    }

    .accordion {
        position: absolute;
        background: #fff;
        z-index: 9;
        margin-top: 2px;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        font-size: medium;
        font-weight: normal;
        
        .price-wrapper{
            height: 20rem;
        }
        // .ant-slider-track{
        //     background-color: #bf2100;
        // }
    }

    .check-box{
        display: block;
        position: relative;
        padding-left: 1.25rem;
        .check-box-input{
            position: absolute;
            margin-top: 0px;
            margin-left: -1.25rem;
            width: 1.5em;
            height: 1.5em;
            accent-color: #bf2100;         
        }
        .check-box-input{
            padding-left: 1.25rem;
        }
        .check-box-label{
            margin-left: 1em;
            align-items: center;
            margin-bottom: 0px !important;
        }
    }

   .price-wrapper{
    .ant-slider-step{
        .ant-slider-dot-active{
            border-color: #bf2100;
        }
    }
    .ant-slider:hover{
        border-color: #bf2100;
    }
    .ant-slider-track{
        background-color: #bf2100 !important;
    }
    .ant-slider-handle, .ant-slider-handle:hover{
        border-color: #bf2100 !important;
    }
    .ant-tooltip-open{
        border-color:#bf2100
    }
   }

   .ant-segmented{
    border-radius: 30px;

    .ant-segmented-item-selected{
        background-color: #bf2100 !important;
        color: #fff !important;
        border-radius: 30px;
    }
    label {
        margin-bottom: 0% !important;
    }
   }
}