

.adsCard{
    border-radius: 15px;
    // border: 2px solid #e7e4e4;
    .cardLink{
        color: black !important;
    }
}
.adsImg{
    padding: 10px 10px 0 10px;
    border-radius: 20px 20px 0 0;
    width: 330px;
    height: 300px ;
    max-width: 100%;
    max-height:300px; 
    object-fit: cover;
}
.ant-skeleton-image{
    border-radius: 8px 8px 0 0;
    width: 330px;
    height: 300px !important;
    max-width: 100%;
    max-height:300px !important; 
    object-fit: cover;
}
