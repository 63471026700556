.navbar-nav{
    .nav-item{
        .nav-link{
            // color: #bf2000cd;
            color: #bf2100;
            
            font-weight: 700;
            &:hover, &:focus{
                color:#bf2100 !important;
            }
        }
    }
}