.slick-arrow.slick-prev,
.slick-arrow.slick-next{
    /* backdrop-filter: blur(10px); */
    /* background-color: rgba(255, 255, 255, 0.5); */
    background: rgb(255, 249, 249);
    box-shadow: 0 20px 60px -10px rgb(186 187 202 / 60%);
    width:4rem;
    height: 3rem;
    border: 3px solid rgb(255, 249, 249);
    margin:0px 10px 0px 10px;
    display:flex;
    align-items:center;
    justify-content: center;
    z-index: 1;
    border-radius:100px;
}
.slick-prev{
    left:0;
    
}
.slick-next{
    right:0;
}
.slick-prev::before,
.slick-next::before{
    display: none;
}

/* .custom-indicator{
    bottom: -65px;
} */