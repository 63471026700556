.checkbox-button {
	input[type="checkbox"]{
		display: none;
	}

	display: inline-block;
	cursor: pointer;
	border-radius: 13px;
	text-align: center;
	transition: all 0.3s ease-in-out;
	color: #000000;
	font-weight: 300;
	user-select: none;
	box-shadow: 0 2px 7px 0 rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.19);
	:hover {
		box-shadow: 0 3px 7px 0 rgba(0,0,0,0.2),0 2px 5px 0 rgba(0,0,0,0.19);
	}

	span {
		display: inline-block;
		border-radius: 10px;
		padding: 10px 20px;
	}
	input[type="checkbox"]:checked + span {
		color:white;
		// TODO: think on which color to keep bf2100 or d31027
		background-image: linear-gradient(to right, #bf2100 0%, #EA384D  51%, #bf2100  100%);

		:hover {
			transition: 0.5s;
			box-shadow: 0 0 20px #eee;
			background-size: 200% auto;
			background-position: right center;
		}
	}
	input[type="checkbox"]:checked + span:hover {
		transition: 0.5s;
		box-shadow: 0 0 20px #eee;
		background-size: 200% auto;
		background-position: right center;
	}
}

